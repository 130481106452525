import Cookies from "js-cookie";

/**
 *
 * @param status
 * @param msg
 * @returns {string}
 */
export const setErrorMessage = (status, msg = "Cannot delete element") => {
  switch (status) {
    case 404: {
      return "error.error_page_404";
    }
    case 401: {
      return "error.error_page_401";
    }
    case 403: {
      return "error.error_page_403";
    }
    case 500: {
      return "error.error_page_500";
    }
    case 422: {
      return "error.error_page_422";
    }
    case 405: {
      return msg;
    }
    case 409: {
      return msg;
    }
    case 400: {
      return msg;
    }
    case 402: {
      return msg;
    }
    default:
      return "error.unexpected_error";
  }
};

/**
 *
 * @param key
 * @param value
 */
export const setLocalStorage = (key, value) => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (e) {
    // catch possible errors:
  }
};

/**
 *
 * @param key
 * @param initialValue
 * @returns {any}
 */
export const getLocalStorage = (key, initialValue) => {
  try {
    const value = localStorage.getItem(key);
    return value ? JSON.parse(value) : initialValue;
  } catch (e) {
    // if error, return initial value
    return initialValue;
  }
};

/**
 * Format event date to be like  "April 19-30 2023"
 * @param {*} firstDate
 * @param {*} firstTime
 * @param {*} secondDate
 * @param {*} secondTime
 * @returns string
 */
export const formatEventDate = (firstDate, firstTime, secondDate, secondTime) => {
  // NOTE: "end_date" is not mandatory, so, it can be null
  // NOTE: "start_date" and "end_date" can have different year
  const startDate = new Date(new Date(firstDate).toLocaleString("en", {timeZone: "UTC"}));
  const endDate = new Date(new Date(secondDate).toLocaleString("en", {timeZone: "UTC"}));
  const langCode = getCurrentLanguageCode();

  let start_date = null;
  let start_time = firstTime ? `${firstTime.split(":")[0]}:${firstTime.split(":")[1]}` : null;
  let end_date = null;
  let end_time = secondTime ? `${secondTime.split(":")[0]}:${secondTime.split(":")[1]}` : null;

  const startDay = startDate.getDate();
  const startMonthName = startDate.toLocaleDateString(langCode, {
    month: "long",
  });
  const startYear = startDate.getFullYear();
  const endDay = endDate.getDate();
  const endMonthName = endDate.toLocaleDateString(langCode, {
    month: "long"
  });
  const endYear = endDate.getFullYear();

  if (langCode == "en") {
    start_date = `${startMonthName} ${startDay}, ${startYear}`;
    if (secondDate) end_date = `${endMonthName} ${endDay}, ${endYear}`;
  } else {
    start_date = `${startDay} ${startMonthName} ${startYear}`;
    if (secondDate) end_date = `${endDay} ${endMonthName} ${endYear}`;
  }

  if (firstTime && secondDate && secondTime) {
    return `${start_date} ${start_time} - ${end_date} ${end_time}`;
  } else if (firstTime && secondDate) {
    return `${start_date} ${start_time} - ${end_date}`;
  } else if (firstTime && secondTime) {
    return `${start_date} ${firstTime} - ${end_time}`;
  } else if (firstTime) {
    return `${start_date} ${firstTime}`;
  } else if (secondDate && secondTime) {
    return `${start_date} - ${end_date} ${end_time}`;
  } else if (secondDate) {
    return `${start_date} - ${end_date}`;
  } else if (secondTime) {
    return `${start_date} - ${secondTime}`;
  } else {
    return `${start_date}`;
  }
};

/**
 * get current Django selected languagd
 * @returns
 */
export const getCurrentLanguageCode = () => {
  const languageCode = document.querySelector("#language-code");
  return languageCode.value;
};

/**
 * Since we cannot reverse Roots in react we need to get link from template
 *
 * @returns
 */
export const getResetPasswordLink = () => {
  const resetPasswordLink = document.querySelector("#reset-password-link");
  return resetPasswordLink.value;
};

/**
 * Since we cannot reverse Roots in react we need to get link from template
 *
 * @returns
 */
export const getMyOrdersLink = () => {
  const myOrdersLink = document.querySelector("#my-orders-link");
  return myOrdersLink.value;
};

/**
 * Since we cannot reverse Roots in react we need to get link from template
 *
 * @returns
 */
export const getMyProfileLink = () => {
  const MyProfileLink = document.querySelector("#my-profile-link");
  return MyProfileLink.value;
};

/**
 * Get cookie by name
 * we can use it to get django csrf token too
 * @param {*} name
 * @returns
 */
export const getCookie = (csrftoken) => {
  return Cookies.get(csrftoken);
};
