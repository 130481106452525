import React from "react";
import Cart from "../../components/Cart";

/**
 * Main Checkout App
 * @returns JSX
 */
const MainApp = () => {
  return (
    <>
      <Cart />
    </>
  );
};
export default MainApp;
