import {
  ADD_TO_CART,
  CHECKOUT,
  DELETE_ITEM_FROM_CART,
  FETCH_CART_DATA,
  GET_ORDER_DETAILS,
  IS_FETCHING_CART_DATA,
  IS_UPDATING_QUANTITY,
  SET_CHECKOUT_ERRORS,
  UPDATE_CART_QUANTITY,
} from "../../constants/ActionTypes";

const INIT_STATE = {
  cart: [],
  order: null,
  orderDetails: null,
  errors: null,
  isUpdatingQuantity: false,
  isFetchingCart: false,
  checkoutErrors: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_CART_DATA: {
      return {
        ...state,
        cart: action.payload,
        checkoutErrors: null,
      };
    }
    case UPDATE_CART_QUANTITY: {
      const updatedItem = action.payload.item;
      const updatedBasket = { ...state.cart.basket };
      const updatedItems = updatedBasket.items.map((item) =>
        item.product_id === updatedItem.product_id &&
        item.extra.date_id === updatedItem.extra.date_id
          ? updatedItem
          : item
      );
      updatedBasket.items = updatedItems;
      return {
        ...state,
        cart: {
          ...state.cart,
          basket: updatedBasket,
          counts: action.payload.counts,
          checkoutErrors: null,
        },
      };
    }
    case DELETE_ITEM_FROM_CART: {
      const deletedItem = action.payload;
      const deletedBasket = { ...state.cart.basket };
      const updatedItems = deletedBasket.items.filter((item) => {
        return !(
          item.product_id === deletedItem.product &&
          item.extra.date_id === deletedItem.date
        );
      });
      deletedBasket.items = updatedItems;
      const totalCount = updatedItems.reduce(
        (acc, item) => acc + item.quantity,
        0
      );

      return {
        ...state,
        cart: {
          ...state.cart,
          basket: deletedBasket,
          counts: {
            ...state.cart.counts,
            tickets: totalCount,
            products: state.cart.products - 1,
          },
        },
      };
    }

    case ADD_TO_CART: {
      return {
        ...state,
        cart: action.payload,
        checkoutErrors: null,
      };
    }
    case IS_UPDATING_QUANTITY: {
      return {
        ...state,
        isUpdatingQuantity: action.payload,
      };
    }
    case CHECKOUT: {
      return {
        ...state,
        order: action.payload,
        cart: [],
        errors: null,
        checkoutErrors: null,
      };
    }
    case SET_CHECKOUT_ERRORS: {
      return {
        ...state,
        checkoutErrors: action.payload,
      };
    }
    case GET_ORDER_DETAILS: {
      return {
        ...state,
        orderDetails: action.payload,
      };
    }

    case IS_FETCHING_CART_DATA: {
      return {
        ...state,
        isFetchingCart: action.payload,
      };
    }
    default:
      return state;
  }
};
