import axios from "axios";
import { store } from "../../../application/Checkout";
import { TOKEN_STORAGE_KEY } from "../constants/ActionTypes";
import { getCookie, getLocalStorage } from "./helpers";

/**
 * Get our Django csrf token
 */
const csrftoken = getCookie("csrftoken");

/**
 * Create Global axios instance so we can use it all across the checkout process
 */
const axiosInstance = axios.create({
  baseURL: "",
  headers: {
    Accepted: "application/json",
    "Content-Type": "application/json",
    "X-CSRFToken": csrftoken,
  },
});

/**
 * add extra config to our axios by intercepting requests
 */
axiosInstance.interceptors.request.use(
  (config) => {
    const state = store.getState();
    const token =
      state?.auth?.token || getLocalStorage(TOKEN_STORAGE_KEY, null);
    if (config.method === "post" && token) {
      config.headers.Authorization = `Token ${token}`;
    }
    return config;
  },
  /* eslint-disable no-undef */
  (error) => Promise.reject(error)
);

export default axiosInstance;
