// Common Actions
export const FETCH_START = "fetch_start";
export const FETCH_SUCCESS = "fetch_success";
export const FETCH_ERROR = "fetch_error";
export const CLEAR_ERROR = "CLEAR_ERROR";
export const SHOW_MESSAGE = "SHOW_MESSAGE";
export const HIDE_MESSAGE = "HIDE_MESSAGE";
export const ON_SHOW_LOADER = "ON_SHOW_LOADER";
export const ON_HIDE_LOADER = "ON_HIDE_LOADER";
export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";

// Shopping Cart Actions
export const FETCH_CART_DATA = "FETCH_CART_DATA";
export const IS_FETCHING_CART_DATA = "IS_FETCHING_CART_DATA";
export const ADD_TO_CART = "ADD_TO_CART";
export const UPDATE_CART_QUANTITY = "UPDATE_CART_QUANTITY";
export const IS_UPDATING_QUANTITY = "IS_UPDATING_QUANTITY";
export const DELETE_ITEM_FROM_CART = "DELETE_ITEM_FROM_CART";
export const GET_ORDER_DETAILS = "GET_ORDER_DETAILS";

// Auth Actions
export const SIGN_IN = "SIGN_IN";
export const SIGN_UP = "SIGN_UP";
export const GET_USER_INFORMATION = "GET_USER_INFORMATION";
export const IS_GETTING_USER_INFO = "IS_GETTING_USER_INFO";
export const GET_USER_INFORMATION_FAILURE = "GET_USER_INFORMATION_FAILURE";
export const CHECKOUT = "CHECKOUT";
export const SET_CHECKOUT_ERRORS = "SET_CHECKOUT_ERRORS";
export const TOKEN_STORAGE_KEY = "TOKEN_STORAGE_KEY";
