import {
  GET_USER_INFORMATION,
  IS_GETTING_USER_INFO,
  GET_USER_INFORMATION_FAILURE,
  SIGN_IN,
  TOKEN_STORAGE_KEY,
} from "../../constants/ActionTypes";
import { getLocalStorage } from "../../util/helpers";

const INIT_STATE = {
  user: null,
  token: getLocalStorage(TOKEN_STORAGE_KEY, null),
  isGettingUserInfo: false,
  errors: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SIGN_IN: {
      return {
        ...state,
        user: action.payload,
      };
    }
    case GET_USER_INFORMATION: {
      return {
        ...state,
        user: action.payload,
        token: action.token
      };
    }
    case IS_GETTING_USER_INFO: {
      return {
        ...state,
        isGettingUserInfo: action.payload,
      };
    }
    case GET_USER_INFORMATION_FAILURE: {
      return {
        ...state,
        user: null,
      };
    }

    default:
      return state;
  }
};
