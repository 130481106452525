import i18next from "i18next";
import i18nextHttpBackend from "i18next-http-backend";

i18next.use(i18nextHttpBackend).init({
  fallbackLng: "fr",
  ns: ["checkout"],
  defaultNS: "checkout",
  debug: false,
  load: "languageOnly",
  interpolation: {
    escapeValue: false,
  },
  backend: {
    loadPath: () => {
        return `${process.env.I18N_BACKEND_URL}{{lng}}/{{ns}}.json`;
    },
  },
  react: { wait: true, useSuspense: true },
});

export default i18next;
