import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSteps } from "react-step-builder";
import { toggleSideBar } from "../../store/actions";
import { useTranslation } from "react-i18next";

/**
 * Show general checkout steps messages
 *
 * @returns
 */
const Header = () => {
  const { t } = useTranslation(["checkout"]);
  const { total, current, hasNext } = useSteps();
  const { sideBarOpen, loading } = useSelector((state) => state.common);
  const checkoutErrors = useSelector((state) => state.cart.checkoutErrors);
  const cart = useSelector((state) => state.cart);

  const dispatch = useDispatch();

  const handleToggleSideBar = () => {
    dispatch(toggleSideBar(!sideBarOpen));
  };

  const handleKeyDown = (event) => {
    if (event.target.id == "nx-open-login-icon" || event.target.id == "nx-open-login") {
      dispatch(toggleSideBar(false));
    }
  };

  React.useEffect(() => {
    window.addEventListener("click", handleKeyDown);
    return () => {
      window.removeEventListener("click", handleKeyDown);
    };
  }, []);

  return (
    <>
      <div className={`cart-header ${!hasNext && "cart-header-white"}`}>
        <div>
          <h2 className="m-0 me-3">
            {loading ? (
              <div></div>
            ) : !hasNext ? (
              checkoutErrors ? (
                t("Checkout error")
              ) : (
                t("Congratulations")
              )
            ) : (
              t("Checkout")
            )}
          </h2>
          {!loading && (cart.cart.basket?.items?.length > 0 || !hasNext) && (
            <span className="header-steps">{`${current}/${total}`}</span>
          )}
        </div>

        {hasNext && (
          <img
            className="sidebar-close"
            onClick={() => handleToggleSideBar()}
            src={`${process.env.IMAGES_BASE_URL}/static/vendors/images/icons/close-white.svg`}
            alt="close"
          />
        )}
      </div>
    </>
  );
};
export default Header;
