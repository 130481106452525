import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCartData, toggleSideBar } from "../store/actions";

/**
 * React app to Overlay
 *
 * @return {*}
 */
const CheckoutOverlay = () => {
  const dispatch = useDispatch();
  const sidebarStatus = useSelector((state) => state.common.sideBarOpen);

  /**
   * Handle sidebar toggle
   */
  const handleToggleSideBar = () => {
    dispatch(getCartData());
    dispatch(toggleSideBar(!sidebarStatus));
  };


  return (
    <>
        {sidebarStatus && (
            <div className="user-overlay d-block" onClick={() => handleToggleSideBar()}></div>
        )}
    </>

  );
};
export default CheckoutOverlay;
