import React from "react";
import { useSelector } from "react-redux";
import Ticket from "./Ticket";
import { useTranslation } from "react-i18next";

/**
 * Order details
 *
 * @return {*}
 */
const Order = () => {
  const { t } = useTranslation(["checkout"]);
  const order = useSelector((state) => state.cart.orderDetails);
  const user = useSelector((state) => state.auth.user);

  return (
    <>
      <div className="order-container">
        <h2 className="order-header">
          {t("Order №")} : {order?.ref}
        </h2>
        <hr />
        <div className="order-user">
          <span className="nx-accordion-header-icon">
            <i className="bi-person"></i>
          </span>
          <h3>{`${user.profile.first_name} ${user.profile.last_name}`}</h3>
        </div>
        <p className="order-description">{t("Order description")}</p>
        <div className="order-items">
          {order?.items?.map((ticket, key) => (
            <Ticket
              key={key}
              ticket={ticket}
              controllableQuantity={false}
              ticketLink={true}
            />
          ))}
        </div>
        <div className="info-message-card">
          <img
            className="info-message"
            src={`${process.env.IMAGES_BASE_URL}/static/vendors/images/icons/bulb.svg`}
            alt="tip"
          />
          {t("Order help")}
        </div>
      </div>
    </>
  );
};

export default Order;
