import React, { useState } from "react";
import { useSelector } from "react-redux";
import Ticket from "./Ticket";
import { useTranslation } from "react-i18next";

/**
 * Our First checkout Step
 * Preview tickets added to cart
 *
 * @param {*} { ticketControllableQuantity = true }
 * @return {*}
 */
const CartPreview = ({ ticketControllableQuantity = true }) => {
  const { t } = useTranslation(["checkout"]);
  const cart = useSelector((state) => state.cart);
  const [ticketBeingUpdated, setTicketBeingUpdated] = useState();

  const tickets = cart.cart.basket?.items.map((ticket) => (
    <Ticket
      key={`${ticket?.product_id}-${ticket?.extra?.date_id}`}
      controllableQuantity={ticketControllableQuantity}
      ticket={ticket}
      ticketBeingUpdated={ticketBeingUpdated}
      setTicketBeingUpdated={setTicketBeingUpdated}
    />
  ));

  const hasMultipleTickets = cart.cart.basket?.items.some(ticket => ticket.quantity > 1);
  const hasMultipleDates = cart.cart.basket?.items.length > 1;
  const is_plural = hasMultipleTickets || hasMultipleDates;

  return (
    <>
      {cart.cart.basket?.items?.length > 0 ? (
        <>
          <h3 className="cart-preview-title">
            {/* the idea here if any of ticket quantity is > 1 then it's plural we put 2 else 1*/}
            {t("Your tickets", { count: is_plural ? 2 : 1 })}
          </h3>
          {tickets}
        </>
      ) : (
        <div className="info-message-card">
          <img
            className="info-message"
            src={`${process.env.IMAGES_BASE_URL}/static/vendors/images/icons/bulb.svg`}
            alt="tip"
          />
          {t("No tickets")}
        </div>
      )}
    </>
  );
};
export default CartPreview;
