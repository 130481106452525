import React, { Suspense, useEffect } from "react";
import MainApp from "./MainApp";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import i18n from "../../../../i18n/i18n";
import { getCurrentLanguageCode } from "../../util/helpers";

/**
 * Our checkout sidebar entry point
 *
 * @returns
 */
export const App = () => {
  useEffect(() => {
    const langCode = getCurrentLanguageCode();
    if (langCode?.length === 2) {
      i18next.changeLanguage(langCode);
    }
  }, []);

  return (
    <Suspense fallback={<div className="loader"></div>}>
      <I18nextProvider i18n={i18n}>
        <MainApp />
      </I18nextProvider>
    </Suspense>
  );
};
export default App;
