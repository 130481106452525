import axios from "../../util/api";
import {
  FETCH_START,
  FETCH_SUCCESS,
  FETCH_ERROR,
  SIGN_IN,
  SIGN_UP,
  GET_USER_INFORMATION,
  IS_GETTING_USER_INFO,
  GET_USER_INFORMATION_FAILURE,
  TOKEN_STORAGE_KEY,
} from "../../constants/ActionTypes";
import { getCurrentLanguageCode, setLocalStorage } from "../../util/helpers";
const currentLocal = getCurrentLanguageCode();

/**
 * Login user
 * @returns
 */
export const signIn = (data) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios
      .post(`/${currentLocal}/auth-api/login/`, data, {
        withCredentials: true,
        maxRedirects: 0,
      })
      .then(({ data }) => {
        dispatch({ type: FETCH_SUCCESS });
        setLocalStorage(TOKEN_STORAGE_KEY, data.token);
        dispatch({
          type: SIGN_IN,
          payload: data,
        });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: error.response.data,
          fieldsErrors: error.response.data,
        });
      });
  };
};

/**
 * Signup User
 * @param {*} data
 * @returns
 */
export const signUp = (userInfo) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios
      .post(`/${currentLocal}/auth-api/register/`, userInfo)
      .then(({ data }) => {
        const email = userInfo.get("email");
        const password = userInfo.get("password");
        // Authenticate user after signup
        dispatch(signIn({ email, password }));
        dispatch({
          type: SIGN_UP,
          payload: data,
        });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: error.response.data,
          fieldsErrors: error.response.data,
        });
      });
  };
};

/**
 * Get user information
 * @returns
 */
export const getUserInformation = () => {
  return (dispatch) => {
    dispatch(isGettingUserInfo(true));
    axios
      .get(`/${currentLocal}/auth-api/me/`)
      .then(({ data }) => {
        dispatch(isGettingUserInfo(false));
        dispatch({
          type: GET_USER_INFORMATION,
          payload: data?.user,
          token: data?.token
        });
      })
      .catch((error) => {
        dispatch(isGettingUserInfo(false));
        dispatch({
          type: GET_USER_INFORMATION_FAILURE,
        });
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

/**
 * UI mainly
 * @param {*} status
 * @returns
 */
export const isGettingUserInfo = (status) => ({
  type: IS_GETTING_USER_INFO,
  payload: status,
});
