import { combineReducers } from "redux";
import Auth from "./Auth";
import Cart from "./Cart";
import Common from "./Common";

export default combineReducers({
  common: Common,
  cart: Cart,
  auth: Auth,
});
