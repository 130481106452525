import React from "react";
import { useSelector } from "react-redux";
import Order from "./Order";
// import { Trans } from "react-i18next";
// import LinkText from "../Common/link";
// import { getMyProfileLink } from "../../util/helpers";

/**
 * Sadly this is the end for our checkout
 * Show order details || nice error (Yes errors are nice)
 *
 * @returns
 */
const Confirmation = () => {
  const loading = useSelector((state) => state.common.loading);
  const checkoutErrors = useSelector((state) => state.cart.checkoutErrors);
  const msg = checkoutErrors?.message;

  /*
  const errorList =
    msg &&
    Object.keys(msg)?.map((key) => {
      const errors = msg?.[key];
      let fieldName = key
        .replace(/_/g, " ")
        .replace(/\b\w/g, (c) => c.toUpperCase());

      return (
        <>
          {errors.map((error, index) => (
            <li key={index}>
              {fieldName}: {error}
            </li>
          ))}
        </>
      );
    });
  */

  return (
    <>
      {loading ? (
        <div className="loader"></div>
      ) : checkoutErrors ? (
        <div className="sticky-message">
          <div className="info-message-card">
            <img
              className="info-message"
              src={`${process.env.IMAGES_BASE_URL}/static/vendors/images/icons/bulb.svg`}
              alt="tip"
            />
            {msg}
          </div>
        </div>
      ) : (
        <Order />
      )}
    </>
  );
};
export default Confirmation;
