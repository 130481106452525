import React from "react";
import { useSelector } from "react-redux";

import { Steps } from "react-step-builder";
import Auth from "./Auth/Auth";
import CartPreview from "./CartPreview";
import Confirmation from "./Confirmation";

/**
 * Steps for checkout
 * We can add more steps here ;)
 *
 * @returns
 */
const CheckoutSteps = () => {
  const user = useSelector((state) => state.auth.user);

  return (
    <div className="cart-preview-main hide-scroll-bar">
      <Steps>
        <div className="step">
          <CartPreview />
        </div>
        <div className="step">
          <CartPreview ticketControllableQuantity={false} />
          <Auth />
        </div>
        <div className="step">{user && <Confirmation />}</div>
      </Steps>
    </div>
  );
};

export default CheckoutSteps;
