import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addEventToCart, getCartData, toggleSideBar } from "../store/actions";

/**
 * React app to show cart info in navigation bar
 *
 * @return {*}
 */
const CartMenu = () => {
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);
  const sidebarStatus = useSelector((state) => state.common.sideBarOpen);

  /**
   * Handle sidebar toggle
   */
  const handleToggleSideBar = () => {
    dispatch(getCartData());
    dispatch(toggleSideBar(!sidebarStatus));
  };

  /**
   * Handle Update cart count
   *
   * @param {*} event
   */
  const handleCartUpdatedEvent = (event) => {
    dispatch(addEventToCart(event.detail));
  };

  useEffect(() => {
    /* this is interesting lol we listen to global dom event we fired in another app */
    window.addEventListener("cartUpdated", handleCartUpdatedEvent);

    /*
     * Upon cartmenu component unmount we need to remove listener
     * We need that to avoid memory leak :( !
     */
    return () => {
      window.removeEventListener("cartUpdated", handleCartUpdatedEvent);
    };
  }, []);

  return (
    <div onClick={() => handleToggleSideBar()}>
      <img
        src={`${process.env.IMAGES_BASE_URL}/static/vendors/images/icons/topbar-ticket.svg`}
        alt="cart"
      />
      {cart?.cart?.counts?.tickets > 0 && (
        <div className="badge-icon">{cart?.cart?.counts?.tickets}</div>
      )}
    </div>
  );
};
export default CartMenu;
