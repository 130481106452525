import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getCartData } from "../store/actions/Cart";
import SideBar from "./SideBar";

/**
 * Cart Component
 * as simple as that !
 *
 * @return {*}
 */
const Cart = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCartData());
  }, []);

  return (
    <>
      <SideBar />
    </>
  );
};
export default Cart;
