import {
  CLEAR_ERROR,
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  HIDE_MESSAGE,
  SHOW_MESSAGE,
  TOGGLE_SIDEBAR,
} from "../../constants/ActionTypes";

/**
 * This is needed to disable scroll on body while sidebar is open
 */
const body = document.querySelector("body");

export const fetchStart = () => {
  return {
    type: FETCH_START,
  };
};

export const fetchSuccess = () => {
  return {
    type: FETCH_SUCCESS,
  };
};

export const fetchError = (error) => {
  return {
    type: FETCH_ERROR,
    payload: error,
  };
};

export const clearError = (field) => {
  return {
    type: CLEAR_ERROR,
    payload: field,
  };
};

export const showMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message,
  };
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE,
  };
};

export const toggleSideBar = (isSideBarOpen) => {
  isSideBarOpen
    ? body.classList.add("sidebar-open")
    : body.classList.remove("sidebar-open");

  return {
    type: TOGGLE_SIDEBAR,
    sidebarOpen: isSideBarOpen,
  };
};
