import React from "react";
import CheckoutSteps from "./Steps/Steps";
import { StepsProvider } from "react-step-builder";
import { useSelector } from "react-redux";
import Header from "./Steps/Header";
import Footer from "./Steps/Footer";

/**
 * Main sidebar
 *
 * @returns
 */
const SideBar = () => {
  const sidebarStatus = useSelector((state) => state.common.sideBarOpen);
  const isFetchingCart = useSelector((state) => state.cart.isFetchingCart);

  return (
    <div className={`cart-sidebar ${sidebarStatus && "open"}`}>
      <StepsProvider>
        <Header />
        {!isFetchingCart ? (
          <CheckoutSteps />
        ) : (
          <div className="mt-5 loader"></div>
        )}
        <Footer />
      </StepsProvider>
    </div>
  );
};
export default SideBar;
