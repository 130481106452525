import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSteps } from "react-step-builder";
import { checkout, toggleSideBar } from "../../store/actions";
import { useTranslation } from "react-i18next";

/**
 * Sidebar footer holds steps for checkout
 *
 * @returns
 */
const Footer = () => {
  const { t } = useTranslation(["checkout"]);
  const dispatch = useDispatch();
  const { prev, next, hasPrev, hasNext, current } = useSteps();
  const cart = useSelector((state) => state.cart);
  const user = useSelector((state) => state.auth.user);
  const sidebarStatus = useSelector((state) => state.common.sideBarOpen);
  const loading = useSelector((state) => state.common.loading);
  const { isFetchingCart, checkoutErrors } = useSelector((state) => state.cart);

  /**
   * Handle sidebar Toggle
   */
  const handleToggleSideBar = () => {
    dispatch(toggleSideBar(!sidebarStatus));
  };

  /**
   * Handle checkout
   */
  const handleCheckout = () => {
    const address = `${user?.profile?.address}, ${user?.profile?.address_city}, ${user?.profile?.address_postalcode}`;
    const { email } = user;
    const data = {
      email,
      shipping_address: address,
      billing_address: address,
      payment_method: "free-payment", // TODO: we will get this later from api
    };
    dispatch(checkout(data));
  };

  /**
   * Handle Next action
   */
  const handleNext = () => {
    next();
    current == 2 && handleCheckout();
  };

  return (
    <>
      {cart.cart.basket?.items?.length > 0 && !checkoutErrors ? (
        <div className="cart-footer">
          {hasPrev && (
            <button
              className="btn btn-previous"
              onClick={prev}
              disabled={loading}
            >
              <img
                src={`${process.env.IMAGES_BASE_URL}/static/vendors/images/icons/arrow-left-3-white.svg`}
                alt="next"
              />
              <span className="ms-2">{t("Previous")}</span>
            </button>
          )}

          {hasNext && (
            <button
              className="btn btn-next"
              disabled={!user && hasPrev}
              onClick={handleNext}
            >
              <span className="me-2">{t("Next")}</span>
              <img
                src={`${process.env.IMAGES_BASE_URL}/static/vendors/images/icons/arrow-right-black-2.svg`}
                alt="next"
              />
            </button>
          )}
        </div>
      ) : (
        <>
          {!isFetchingCart && (
            <div className="cart-footer white">
              <div
                className="footer-return-event"
                onClick={handleToggleSideBar}
              >
                <img
                  src={`${process.env.IMAGES_BASE_URL}/static/vendors/images/icons/arrow-left-3-black.svg`}
                  alt="next"
                />
                <span className="ms-2">{t("Return")}</span>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};
export default Footer;
