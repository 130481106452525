import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSteps } from "react-step-builder";
import { DeleteItemFromCart } from "../../store/actions";
import { formatEventDate, getMyOrdersLink } from "../../util/helpers";
import InputStepper from "../Common/InputStepper";
import { useTranslation } from "react-i18next";

/**
 * Event ticket
 *
 * @param {*} {
 *   ticket,
 *   ticketBeingUpdated = null,
 *   setTicketBeingUpdated = () => {},
 *   controllableQuantity = true,
 *   ticketLink = null,
 * }
 * @return {*}
 */
const Ticket = ({
  ticket,
  ticketBeingUpdated = null,
  setTicketBeingUpdated = () => {},
  controllableQuantity = true,
  ticketLink = null,
}) => {
  const { t } = useTranslation(["checkout"]);
  const dispatch = useDispatch();
  const { hasNext } = useSteps();

  const isUpdatingQuantity = useSelector(
    (state) => state.cart.isUpdatingQuantity
  );

  // Our Ticket code its (productid-dateId)
  const ticketCode = `${ticket?.product_id}-${ticket?.extra?.date_id}`;

  /**
   * Handle ticket delete
   * We set also loading indicator :)
   * That indicator need to be sent as callback fucntion to the action creator
   *
   * @param {*} ticket
   */
  const handleDeleteTicket = (ticket) => {
    const data = {
      product: ticket?.product_id,
      date: ticket?.extra?.date_id,
    };
    setTicketBeingUpdated(`${ticket?.product_id}-${ticket?.extra?.date_id}`);
    dispatch(DeleteItemFromCart(data, setTicketBeingUpdated));
  };

  return (
    <>
      <div className="checkout-ticket-container">
        <div
          className={`checkout-ticket-loading ${
            ticketCode == ticketBeingUpdated && isUpdatingQuantity && "d-flex"
          }`}
        >
          <div className="loader"></div>
        </div>
        <p className="checkout-ticket-title">{ticket?.product?.name}</p>
        <span className="checkout-ticket-date">
          {formatEventDate(
            ticket?.extra?.start_date,
            ticket?.extra?.start_time,
            ticket?.extra?.end_date,
            ticket?.extra?.end_time
          )}
        </span>
        <hr className="checkout-ticket-separator" />
        <div className="checkout-ticket-footer">
          {controllableQuantity ? (
            <>
              <p className="checkout-ticket-footer-label">
                {t("Quantity of tickets")}
              </p>
              <div className="d-flex justify-content-between">
                <InputStepper
                  ticket={ticket}
                  ticketCode={ticketCode}
                  setTicketBeingUpdated={setTicketBeingUpdated}
                />
                <div className="checkout-delete-ticket">
                  <img
                    className="delete-ticket"
                    onClick={() => handleDeleteTicket(ticket)}
                    src={`${process.env.IMAGES_BASE_URL}/static/vendors/images/icons/delete.svg`}
                    alt="delete ticket"
                  />
                </div>
              </div>
            </>
          ) : (
            <div className="d-flex justify-content-between">
              <p className="checkout-ticket-footer-label">
                {t("Tickets", { count: ticket.quantity })}
              </p>
              {!hasNext && ticketLink && (
                <>
                  <div className="nx-event-btn">
                    <a href={getMyOrdersLink()}>{t("See tickets", { count: ticket.quantity })}</a>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default Ticket;
