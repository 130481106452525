import {
  CLEAR_ERROR,
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  HIDE_MESSAGE,
  SHOW_MESSAGE,
  TOGGLE_SIDEBAR,
} from "../../constants/ActionTypes";

const INIT_STATE = {
  error: "",
  loading: false,
  message: "",
  sideBarOpen: false,
  fieldsErrors: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case TOGGLE_SIDEBAR: {
      return {
        ...state,
        sideBarOpen: action.sidebarOpen,
      };
    }
    case FETCH_START: {
      return {
        ...state,
        error: "",
        message: "",
        loading: true,
        fieldsErrors: {},
      };
    }
    case FETCH_SUCCESS: {
      return {
        ...state,
        error: "",
        message: "",
        loading: false,
        fieldsErrors: null,
      };
    }
    case SHOW_MESSAGE: {
      return { ...state, error: "", message: action.payload, loading: false };
    }
    case FETCH_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
        message: "",
        fieldsErrors: action.fieldsErrors,
      };
    }
    case HIDE_MESSAGE: {
      return {
        ...state,
        loading: false,
        error: "",
        message: "",
        fieldsErrors: null,
      };
    }
    case CLEAR_ERROR: {
      const updatedFieldsErrors = Object.assign({}, state.fieldsErrors);
      delete updatedFieldsErrors[action.payload];
      return {
        ...state,
        fieldsErrors: updatedFieldsErrors,
      };
    }
    default:
      return state;
  }
};
