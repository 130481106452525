import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import CartMenu from "../components/checkout/components/CartMenu";
import CheckoutOverlay from "../components/checkout/components/CheckoutOverlay";
import CheckoutApp from "../components/checkout/containers/App";
import configureStore from "../components/checkout/store";

/**
 * Configure our redux store
 */
export const store = configureStore();

/**
 * Show cart in menu
 * Why here ?
 * We need to use the same redux store INSTANCE
 *
 * @return {*}
 */
const NavCart = () => {
  return (
    <Provider store={store}>
      <CartMenu />
    </Provider>
  );
};

/**
 * Our Main checkout sidebar App
 * We use The same store INSTANCE as for NavCart app
 *
 * @return {*}
 */
const Checkout = () => {
  return (
    <Provider store={store}>
      <CheckoutApp />
    </Provider>
  );
};


/**
 * Our Main checkout sidebar App
 * We use The same store INSTANCE as for NavCart app
 *
 * @return {*}
 */
const CheckoutOverlayApp = () => {
  return (
    <Provider store={store}>
      <CheckoutOverlay />
    </Provider>
  );
};

/**
 * Attach both apps to the DOM
 */
const domNodeNavCart = document.querySelector("#nx-cart-app");
const domNodeCheckout = document.querySelector("#checkout-root");
const domNodeCheckoutOverlay = document.querySelector("#checkout-overlay");

if (domNodeNavCart) {
  const rootNavCart = createRoot(domNodeNavCart);
  rootNavCart.render(<NavCart />);
}

if (domNodeCheckout) {
  const rootCheckout = createRoot(domNodeCheckout);
  rootCheckout.render(<Checkout />);
}

if (domNodeCheckoutOverlay) {
  const rootCheckout = createRoot(domNodeCheckoutOverlay);
  rootCheckout.render(<CheckoutOverlayApp />);
}