import React from "react";
import { useDispatch } from "react-redux";
import { DeleteItemFromCart, updateQuantity } from "../../store/actions";

/**
 * Input stepper to increament or decrement Event quantity
 *
 * @param {*} param0
 * @returns
 */
const InputStepper = ({
  ticket,
  ticketCode,
  setTicketBeingUpdated = () => {},
}) => {
  const dispatch = useDispatch();

  /**
   * Event details
   */
  const data = {
    product: ticket?.product_id,
    date: ticket?.extra?.date_id,
  };

  /**
   * Awesome user want more tickets lol
   * Lets increament Quantity
   */
  const handleIncrementQuanity = () => {
    setTicketBeingUpdated(ticketCode);
    dispatch(
      updateQuantity(
        { ...data, quantity: ticket?.quantity + 1 },
        setTicketBeingUpdated
      )
    );
  };

  /**
   * Oops user changed his mind :(
   * his girl is not comming, so let's decrement quantity.
   */
  const handleDecrementQuanity = () => {
    setTicketBeingUpdated(ticketCode);

    ticket?.quantity - 1 <= 0
      ? dispatch(DeleteItemFromCart(data, setTicketBeingUpdated))
      : dispatch(
          updateQuantity(
            { ...data, quantity: ticket?.quantity - 1 },
            setTicketBeingUpdated
          )
        );
  };

  return (
    <div className="input-stepper">
      <button onClick={handleDecrementQuanity}>-</button>
      <span>{ticket.quantity}</span>
      <button onClick={handleIncrementQuanity}>+</button>
    </div>
  );
};
export default InputStepper;
