import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserInformation } from "../../../store/actions/Auth";
import Login from "../Login";
import Singup from "../Signup";
import { useTranslation } from "react-i18next";

/**
 * Auth staff here
 * Let's Try to login or register that visitor ;)
 *
 * @returns
 */
const Auth = () => {
  const { t } = useTranslation(["checkout"]);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const isGettingUserInfo = useSelector(
    (state) => state.auth.isGettingUserInfo
  );

  useEffect(() => {
    dispatch(getUserInformation());
  }, []);

  if (isGettingUserInfo) {
    return <div className="loader"></div>;
  } else if (!user) {
    return (
      <>
        <div className="nx-page-login pt-0">
          <h3 className="cart-preview-title text-center">
            {t("Login required")}
          </h3>
          <Login />
          <Singup />
        </div>
      </>
    );
  }
};
export default Auth;
