import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearError, signIn } from "../../store/actions";
import { useTranslation } from "react-i18next";
import { getResetPasswordLink } from "../../util/helpers";

/**
 * Long JSX component, but it's just login form
 *
 * @returns
 */
const Login = () => {
  const { t } = useTranslation(["checkout"]);
  const dispatch = useDispatch();
  const fieldsErrors = useSelector((state) => state.common.fieldsErrors);
  const loading = useSelector((state) => state.common.loading);

  // eslint-disable-next-line
  const [email, setEmail] = useState();
  // eslint-disable-next-line
  const [password, setPassword] = useState();

  /**
   * Handle User authentication
   * @param {*} e
   */
  const handleLogin = (e) => {
    e.preventDefault();
    dispatch(signIn({ email: email, password: password }));
  };

  const handleEmailChange = (value) => {
    value.length > 0 && dispatch(clearError("email"));
    setEmail(value);
  };

  const handlePasswordChange = (value) => {
    value.length > 0 && dispatch(clearError("password"));
    setPassword(value);
  };

  return (
    <div className="row justify-content-center">
      <div className="col">
        <div className="accordion" id="nx-login-form-accordion">
          <div className="accordion-item">
            <h2 className="accordion-header" id="nx-flush-login">
              <button
                type="button"
                className="accordion-button collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#nx-flush-toggle-login"
                aria-controls="nx-flush-toggle-login"
                aria-expanded="false"
              >
                <span className="nx-accordion-header-icon">
                  <i className="bi-person"></i>
                </span>
                <span>{t("Login")}</span>
              </button>
            </h2>
            <div
              id="nx-flush-toggle-login"
              aria-labelledby="nx-flush-login"
              data-bs-parent=".nx-page-login"
              className="accordion-collapse collapse"
            >
              <div className="accordion-body">
                <form className="nx-login-form pt-3">
                  <div className="mb-4">
                    <label htmlFor="nx-login-email" className="form-label">
                      {t("Email")}
                    </label>
                    <input
                      className={`form-control ${
                        fieldsErrors?.email && "is-invalid"
                      }`}
                      type="email"
                      name="email"
                      placeholder={t("Email")}
                      id="nx-login-email"
                      onChange={(e) => handleEmailChange(e.target.value)}
                      value={email}
                      required
                    />
                    {fieldsErrors?.email && (
                      <div className="invalid-feedback">
                        {fieldsErrors?.email}
                      </div>
                    )}
                  </div>
                  <div className="mb-4">
                    <label htmlFor="nx-login-password" className="form-label">
                      {t("Password")}
                    </label>
                    <input
                      className={`form-control ${
                        (fieldsErrors?.password ||
                          fieldsErrors?.non_field_errors) &&
                        "is-invalid"
                      }`}
                      type="password"
                      name="password"
                      value={password}
                      placeholder={t("Password")}
                      id="nx-login-password"
                      onChange={(e) => handlePasswordChange(e.target.value)}
                      required
                    />
                    {(fieldsErrors?.password ||
                      fieldsErrors?.non_field_errors) && (
                      <div className="invalid-feedback">
                        {fieldsErrors?.password}
                        {fieldsErrors?.non_field_errors}
                      </div>
                    )}
                  </div>
                  <div className="d-grid mb-4">
                    <a
                      href={getResetPasswordLink()}
                      className="nx-link-reset-password"
                    >
                      {t("Forgot password?")}
                    </a>
                  </div>
                  <div className="mb-4"></div>
                  <div className="mt-5 mb-4">
                    <button
                      disabled={loading}
                      type="submit"
                      className={`btn btn-primary ${loading && "loading"}`}
                      onClick={(e) => handleLogin(e)}
                    >
                      {loading && <span className="loader me-2"></span>}
                      {t("Login")}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Login;
