import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearError, signUp } from "../../store/actions";
import { useTranslation } from "react-i18next";

/**
 * Another long JSX component again it's just signup
 *
 * @returns
 */
const Singup = () => {
  const { t } = useTranslation(["checkout"]);
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.common.loading);
  const fieldsErrors = useSelector((state) => state.common.fieldsErrors);
  const [formData, setFormData] = useState(new FormData());

  /**
   * Handle singup form input change
   * @param {*} event
   */
  const handleInputChange = (event) => {

    const checkboxValue = event.target.type === 'checkbox' && event.target.checked;
    const { name, value } = event.target;

    if(event.target.type === 'checkbox') {
        dispatch(clearError(name));
        formData.set(name, checkboxValue);
        setFormData(formData);
    } else {
        dispatch(clearError(name));
        formData.set(name, value);
        setFormData(formData);
    }

  };

  /**
   * Handle signup
   *
   * @param {*} e
   */
  const handleSignup = (e) => {
    e.preventDefault();
    dispatch(signUp(formData));
  };

  return (
    <>
      <div className="row justify-content-center">
        <div className="col">
          <div className="accordion" id="nx-register-form-accordion">
            <div className="accordion-item">
              <h2 className="accordion-header" id="nx-flush-register">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#nx-flush-toggle-register"
                  aria-expanded="false"
                  aria-controls="nx-flush-toggle-register"
                >
                  <span className="nx-accordion-header-icon">
                    <i className="bi bi-person-plus"></i>
                  </span>
                  <span>{t("Create an account")}</span>
                </button>
              </h2>
              <div
                id="nx-flush-toggle-register"
                className="accordion-collapse collapse"
                aria-labelledby="nx-flush-register"
                data-bs-parent=".nx-page-login"
              >
                <div className="accordion-body">
                  <form id="nx-form-register" className="nx-login-form pt-3">
                    <div className="mb-4">
                      <label htmlFor="id_first_name" className="form-label">
                        {t("First Name")}*
                      </label>
                      <input
                        onChange={handleInputChange}
                        type="text"
                        name="first_name"
                        maxLength={120}
                        className={`form-control ${
                          fieldsErrors?.first_name && "is-invalid"
                        }`}
                        required=""
                        id="id_first_name"
                      />
                      {fieldsErrors?.first_name && (
                        <div className="invalid-feedback">
                          {fieldsErrors?.first_name}
                        </div>
                      )}
                    </div>
                    <div className="mb-4">
                      <label htmlFor="id_last_name" className="form-label">
                        {t("Last Name")}*
                      </label>
                      <input
                        className={`form-control ${
                          fieldsErrors?.last_name && "is-invalid"
                        }`}
                        onChange={handleInputChange}
                        type="text"
                        name="last_name"
                        maxLength={120}
                        required=""
                        id="id_last_name"
                        autoComplete="off"
                      />
                      {fieldsErrors?.last_name && (
                        <div className="invalid-feedback">
                          {fieldsErrors?.last_name}
                        </div>
                      )}
                    </div>
                    <div className="mb-4">
                      <label htmlFor="id_password" className="form-label">
                        {t("Password")}*
                      </label>
                      <input
                        onChange={handleInputChange}
                        type="password"
                        name="password"
                        className={`form-control ${
                          fieldsErrors?.password && "is-invalid"
                        }`}
                        required=""
                        id="id_password"
                        autoComplete="off"
                      />
                      {fieldsErrors?.password && (
                        <div className="invalid-feedback">
                          {fieldsErrors?.password}
                        </div>
                      )}
                      <div className="help-text">
                        {t("Password tip")}
                      </div>
                    </div>
                    <div className="mb-4">
                      <label htmlFor="id_password2" className="form-label">
                        {t("Password Confirmation")}*
                      </label>
                      <input
                        onChange={handleInputChange}
                        type="password"
                        name="password2"
                        className={`form-control ${
                          fieldsErrors?.password2 && "is-invalid"
                        }`}
                        required=""
                        id="id_password2"
                        autoComplete="off"
                      />
                      {fieldsErrors?.password2 && (
                        <div className="invalid-feedback">
                          {fieldsErrors?.password2}
                        </div>
                      )}
                    </div>
                    <div className="mb-4">
                      <label htmlFor="id_email" className="form-label">
                        {t("Email")}*
                      </label>
                      <input
                        onChange={handleInputChange}
                        type="email"
                        name="email"
                        className={`form-control ${
                          fieldsErrors?.email && "is-invalid"
                        }`}
                        required=""
                        id="id_email"
                      />
                      {fieldsErrors?.email && (
                        <div className="invalid-feedback">
                          {fieldsErrors?.email}
                        </div>
                      )}
                    </div>
                    <div className="mb-4">
                      <label htmlFor="id_address" className="form-label">
                        {t("Address")}
                      </label>
                      <input
                        onChange={handleInputChange}
                        type="text"
                        name="address"
                        maxLength={250}
                        className={`form-control ${
                          fieldsErrors?.address && "is-invalid"
                        }`}
                        id="id_address"
                      />
                      {fieldsErrors?.address && (
                        <div className="invalid-feedback">
                          {fieldsErrors?.address}
                        </div>
                      )}
                    </div>
                    <div className="mb-4">
                      <label htmlFor="id_address_city" className="form-label">
                        {t("City")}
                      </label>
                      <input
                        onChange={handleInputChange}
                        type="text"
                        name="address_city"
                        maxLength={120}
                        className={`form-control ${
                          fieldsErrors?.address_city && "is-invalid"
                        }`}
                        id="id_address_city"
                      />
                      {fieldsErrors?.address_city && (
                        <div className="invalid-feedback">
                          {fieldsErrors?.address_city}
                        </div>
                      )}
                    </div>
                    <div className="mb-4">
                      <label
                        htmlFor="id_address_postalcode"
                        className="form-label"
                      >
                        {t("Postal/ZIP Code")}
                      </label>
                      <input
                        onChange={handleInputChange}
                        type="text"
                        name="address_postalcode"
                        maxLength={7}
                        className={`form-control ${
                          fieldsErrors?.address_postalcode && "is-invalid"
                        }`}
                        id="id_address_postalcode"
                      />
                      {fieldsErrors?.address_postalcode && (
                        <div className="invalid-feedback">
                          {fieldsErrors?.address_postalcode}
                        </div>
                      )}
                    </div>
                    <div className="mb-4">
                      <label htmlFor="id_phone" className="form-label">
                        {t("Phone Number")}
                      </label>
                      <input
                        onChange={handleInputChange}
                        type="tel"
                        name="phone"
                        className={`form-control ${
                          fieldsErrors?.phone && "is-invalid"
                        }`}
                        id="id_phone"
                      />
                      {fieldsErrors?.phone && (
                        <div className="invalid-feedback">
                          {fieldsErrors?.phone}
                        </div>
                      )}
                    </div>
                    <div className="mb-4">
                      <div className="form-check">
                        <input
                          onChange={handleInputChange}
                          className="form-check-input"
                          type="checkbox"
                          name="is_accept_termsandconditions"
                          defaultValue="false"
                          id="id_is_accept_termsandconditions"
                        />
                        <label
                          htmlFor="id_is_accept_termsandconditions"
                          className="form-check-label"
                        >
                          {t("I accept Terms and Conditions")}*
                        </label>
                        {fieldsErrors?.is_accept_termsandconditions && (
                        <div className="invalid-feedback d-block">
                          {fieldsErrors?.is_accept_termsandconditions}
                        </div>
                      )}
                      </div>

                    </div>
                    <div className="mb-4">
                      <div className="form-check">
                        <input
                          onChange={handleInputChange}
                          className="form-check-input"
                          type="checkbox"
                          name="is_accept_tobecontacted"
                          defaultValue="true"
                          id="id_is_accept_tobecontacted"
                        />
                        <label
                          htmlFor="id_is_accept_tobecontacted"
                          className="form-check-label"
                        >
                          {t("Accept newsletter")}
                        </label>
                      </div>
                    </div>
                    <div className="mb-4">
                      <input
                        onChange={handleInputChange}
                        type="hidden"
                        name="g_recaptcha_response"
                        id="g-recaptcha-response-register"
                        className="g-recaptcha-response"
                      />
                    </div>
                    <div className="mt-5 mb-4">
                      <button
                        onClick={handleSignup}
                        type="submit"
                        className={`btn btn-primary ${loading && "loading"}`}
                      >
                        {loading && <span className="loader me-2"></span>}
                        {t("Create an account")}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Singup;
