import axios from "../../util/api";
import { store } from "../../../../application/Checkout";
import { TOKEN_STORAGE_KEY } from "../../constants/ActionTypes";
import {
  FETCH_CART_DATA,
  FETCH_ERROR,
  ADD_TO_CART,
  UPDATE_CART_QUANTITY,
  IS_UPDATING_QUANTITY,
  DELETE_ITEM_FROM_CART,
  IS_FETCHING_CART_DATA,
  CHECKOUT,
  FETCH_START,
  FETCH_SUCCESS,
  GET_ORDER_DETAILS,
  SET_CHECKOUT_ERRORS,
} from "../../constants/ActionTypes";
import { getCurrentLanguageCode, getCookie, getLocalStorage } from "../../util/helpers";

/**
 * Current Django Language code
 */
const currentLocal = getCurrentLanguageCode();

/**
 * Get our Django csrf token
 */
const csrftoken = getCookie("csrftoken");

/**
 * Fetch current cart
 *
 * @returns
 */
export const getCartData = () => {
  return (dispatch) => {
    dispatch(isFetchingCartData(true));
    // make sure to update csrf
    axios.defaults.headers.common["X-CSRFToken"] = csrftoken;
    axios
      .get(`/${currentLocal}/shop-api/cart/`)
      .then(({ data }) => {
        dispatch(isFetchingCartData(false));
        dispatch({
          type: FETCH_CART_DATA,
          payload: data,
        });
      })
      .catch((error) => {
        dispatch(isFetchingCartData(false));
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

/**
 * Update Quantity
 *
 * @param {*} data
 * @returns
 */
export const updateQuantity = (data, callback) => {
  return (dispatch) => {
    dispatch(isUpdatingQuantity(true));
    // make sure to update csrf
    axios.defaults.headers.common["X-CSRFToken"] = csrftoken;
    axios
      .patch(`/${currentLocal}/shop-api/change-quantity/event/`, { ...data })
      .then(({ data }) => {
        dispatch(isUpdatingQuantity(false));
        callback(null);
        dispatch({
          type: UPDATE_CART_QUANTITY,
          payload: data,
        });
        if (data.counts.expired_tickets_msg) {
          dispatch(getCartData());
        }
      })
      .catch((error) => {
        callback(null);
        dispatch(isUpdatingQuantity(false));
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

/**
 * Delete Item from Cart
 *
 * @param {*} details
 * @returns
 */
export const DeleteItemFromCart = (details, callback) => {
  return (dispatch) => {
    dispatch(isUpdatingQuantity(true));
    // make sure to update csrf
    axios.defaults.headers.common["X-CSRFToken"] = csrftoken;
    axios
      .delete(`/${currentLocal}/shop-api/delete/event/`, {
        data: {
          ...details,
        },
      })
      .then(() => {
        dispatch(isUpdatingQuantity(false));
        callback(null);
        dispatch({
          type: DELETE_ITEM_FROM_CART,
          payload: details,
        });
      })
      .catch((error) => {
        dispatch(isUpdatingQuantity(false));
        callback(null);
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

/**
 * Checkout ! lets take his  money :)
 * Oops tickets are free currently :(
 *
 * @param {*} data
 * @returns
 */
export const checkout = (data) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    const state = store.getState();
    const token = state?.auth?.token || getLocalStorage(TOKEN_STORAGE_KEY, null);
    // make sure request receive POST (not body)
    let dataForm = new FormData();
    dataForm.append("email", data.email);
    dataForm.append("shipping_address", data.shipping_address);
    dataForm.append("billing_address", data.billing_address);
    dataForm.append("payment_method", data.payment_method);
    // make sure to update csrf
    axios.defaults.headers.common["X-CSRFToken"] = csrftoken;
    axios
      .post(`/${currentLocal}/shop-api/checkout/`, dataForm, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": `Token ${token}`,
          "token": `Token ${token}`
        }
      })
      .then(({ data }) => {
        // GET ORDER DETAILS
        dispatch(getOrder(data.order.url.replace("http:", location.protocol)));
        dispatch({
          type: CHECKOUT,
          payload: data?.order,
        });
      })
      .catch((error) => {
        if (error.response.data.redirect) {
          window.location.href = error.response.data.redirect;
        }
        // Set checkout errors
        dispatch({ type: SET_CHECKOUT_ERRORS, payload: error.response.data });
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

/**
 * Get order details
 *
 * @param {*} orderUrl
 * @returns
 */
export const getOrder = (orderUrl) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    // make sure to update csrf
    axios.defaults.headers.common["X-CSRFToken"] = csrftoken;
    axios
      .get(orderUrl)
      .then(({ data }) => {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({
          type: GET_ORDER_DETAILS,
          payload: data,
        });
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

/**
 * Add event data received from custom event to redux store
 * @param {*} data
 * @returns
 */
export const addEventToCart = (data) => ({
  type: ADD_TO_CART,
  payload: data,
});

/**
 * UI mainly
 * @param {*} status
 * @returns
 */
export const isUpdatingQuantity = (status) => ({
  type: IS_UPDATING_QUANTITY,
  payload: status,
});

/**
 * UI mainly
 * @param {*} status
 * @returns
 */
export const isFetchingCartData = (status) => ({
  type: IS_FETCHING_CART_DATA,
  payload: status,
});
